import {
  IconButton,
  type TextAreaProps,
  Spinner,
  Flex,
  TextArea,
} from "@appsmith/wds";
import React, { useCallback } from "react";
import styles from "./styles.module.css";

export interface ChatInputProps extends TextAreaProps {
  /** callback function when the user submits the chat input */
  onSubmit?: () => void;
  /** flag for disable the submit button */
  isSubmitDisabled?: boolean;
}

export function ChatInput(props: ChatInputProps) {
  const {
    contextualHelp,
    errorMessage,
    isDisabled,
    isInvalid,
    isLoading,
    isReadOnly,
    isRequired,
    isSubmitDisabled,
    label,
    onChange,
    onSubmit,
    prefix,
    suffix: suffixProp,
    value,
    ...rest
  } = props;

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter") {
        // Allow Shift + Enter to add a newline
        if (event.shiftKey) {
          return;
        }

        // Prevent submission if disabled or loading
        if (Boolean(isSubmitDisabled) || Boolean(isLoading)) {
          event.preventDefault();

          return;
        }

        // Submit the message on Enter
        event.preventDefault();
        onSubmit?.();
      }
    },
    [onSubmit, isSubmitDisabled, isLoading],
  );

  const suffix = (function () {
    if (Boolean(suffixProp)) return suffixProp;

    if (Boolean(isLoading)) {
      return (
        <Flex padding="spacing-1">
          <Spinner />
        </Flex>
      );
    }

    return (
      <IconButton
        data-testid="t--ai-chat-input-send-button"
        icon="arrow-up"
        isDisabled={Boolean(isDisabled) || Boolean(isSubmitDisabled)}
        onPress={onSubmit}
      />
    );
  })();

  return (
    <TextArea
      contextualHelp={contextualHelp}
      data-testid="t--ai-chat-input-text-area"
      errorMessage={errorMessage}
      fieldClassName={styles.chatField}
      inputClassName={styles.chatInput}
      isDisabled={Boolean(isDisabled)}
      isInvalid={isInvalid}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      label={label}
      maxRows={10}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      prefix={prefix}
      rows={1}
      size="large"
      suffix={suffix}
      value={value}
      {...rest}
    />
  );
}
