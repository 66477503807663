import {
  BlueprintOperationTypes,
  type FlattenedWidgetProps,
  type WidgetDefaultProps,
} from "WidgetProvider/constants";
import { LayoutSystemTypes } from "layoutSystems/types";
import type { UpdatePropertyArgs } from "sagas/WidgetBlueprintSagas";
import { ResponsiveBehavior } from "layoutSystems/common/utils/constants";
import type { CanvasWidgetsReduxState } from "ee/reducers/entityReducers/canvasWidgetsReducer";
import { PluginPackageName } from "entities/Plugin";
import { type DynamicPath } from "utils/DynamicBindingUtils";
import type { ActionData } from "ee/reducers/entityReducers/actionsReducer";
import { createMessage } from "ce/constants/messages";
import { CHAT_SIZE } from "../../component/types";
import { AI_DATASOURCE } from "ee/constants/messages";

export const defaultsConfig = {
  chatHeightSize: CHAT_SIZE.FIT_PAGE,
  isVisible: true,
  widgetName: "AIChat",
  widgetType: "AI_CHAT",
  version: 1,
  responsiveBehavior: ResponsiveBehavior.Fill,
  initialAssistantMessage: `Hello, I'm your customised AI assistant here to help you build your application.
What would you like to build today?`,
  initialAssistantSuggestions: [
    {
      label: "Create support dashboard",
      value: "How do I build a support dashboard?",
    },
    {
      label: "Fetch Zendesk tickets",
      value: "How do I fetch tickets from Zendesk?",
    },
    {
      label: "Create signups chart",
      value: "How do I create a line chart of new signups in postgres?",
    },
    {
      label: "Create signature pad",
      value: "How do I create a custom signature pad widget?",
    },
  ],
  promptInputPlaceholder: "How do I build a:",
  assistantName: "App Building Assistant",
  chatDescription: `The Appsmith Developer Assistant is your essential companion for building and optimizing applications. Whether you're experienced or new to Appsmith, this AI assistant offers:

  - Guided Development: Step-by-step instructions for connecting to databases and APIs.
  - Code Snippets: Ready-to-use code examples for quick implementation.
  - Best Practices: Effective techniques for leveraging Appsmith features.
  - Troubleshooting Support: Solutions to common development issues.`,
  blueprint: {
    operations: [
      {
        type: BlueprintOperationTypes.ADD_ACTION,
        actionType: "CREATE_OR_UPDATE_DATASOURCE_WITH_ACTION",
        payload: {
          datasourceName: createMessage(AI_DATASOURCE),
          pluginPackageName: PluginPackageName.APPSMITH_AI,
          actionConfig: {
            name: "ChatQuery",
            // TODO: Need to get these values from Plugin Form configs.
            actionConfiguration: {
              formData: {
                usecase: { data: "AI_CHAT_ASSISTANT" },
                aiChatAssistant: {
                  input: {
                    provider: "openai",
                    model: "gpt-4o-mini",
                    requestType: "{{this.params.requestType || 'getThreads'}}",
                    message: "{{this.params.message}}",
                    widgetId: "{{this.params.widgetId}}",
                    threadId: "{{this.params.threadId}}",
                    citationId: "{{this.params.citationId}}",
                    messageId: "{{this.params.messageId}}",
                    limit: "{{this.params.limit}}",
                    username: "{{this.params.appsmithUsername}}",
                    kValue: 5,
                    minSimilarityThreshold: 0.0,
                    temperature: 0.7,
                    topP: 0.7,
                    searchAllDocuments: true,
                  },
                },
              },
              timeoutInMillisecond: 60000,
            },
          },
        },
      },
      {
        type: BlueprintOperationTypes.MODIFY_PROPS,
        fn: (
          widget: FlattenedWidgetProps,
          widgets: CanvasWidgetsReduxState,
          _parent: FlattenedWidgetProps,
          layoutSystemType: LayoutSystemTypes,
          addActionResult: ActionData,
        ) => {
          if (layoutSystemType !== LayoutSystemTypes.ANVIL) return [];

          const dynamicBindingPathList: DynamicPath[] = [
            {
              key: "queryData",
            },
          ];

          const updates: UpdatePropertyArgs[] = [];
          const parentId = widget.parentId;

          if (parentId) {
            const parentWidget = widgets[parentId];

            // we want to proceed only if the parent is a zone widget and has no children
            if (
              parentWidget.children?.length === 0 &&
              parentWidget.type === "ZONE_WIDGET"
            ) {
              updates.push({
                widgetId: parentId,
                propertyName: "elevatedBackground",
                propertyValue: false,
              });
            }
          }

          updates.push(
            {
              widgetId: widget.widgetId,
              propertyName: "chatQuery",
              propertyValue: addActionResult.config.name,
            },
            {
              widgetId: widget.widgetId,
              propertyName: "queryData",
              propertyValue: `{{${addActionResult.config.name}.data}}`,
            },
            {
              widgetId: widget.widgetId,
              propertyName: "queryRun",
              propertyValue: addActionResult.config.name,
            },
            {
              widgetId: widget.widgetId,
              propertyName: "dynamicBindingPathList",
              propertyValue: dynamicBindingPathList,
            },
          );

          return updates;
        },
      },
    ],
  },
} as unknown as WidgetDefaultProps;
