import { objectKeys } from "@appsmith/utils";
import type { ActionData } from "ce/reducers/entityReducers/actionsReducer";
import { getJSCollections } from "ce/selectors/entitiesSelector";
import { isApiResponseError } from "../helpers";
import { getCurrentEnvironmentId } from "ee/selectors/environmentSelectors";
import type { SetterConfig, Stylesheet } from "entities/AppTheming";
import type { JSAction } from "entities/JSCollection";
import memoizeOne from "memoize-one";
import { create } from "mutative";
import React, { type ReactNode } from "react";
import { getIsViewMode } from "selectors/editorSelectors";
import store from "store";
import type {
  AnvilConfig,
  AutocompletionDefinitions,
  WidgetBaseConfiguration,
  WidgetDefaultProps,
} from "WidgetProvider/constants";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { ContainerWidgetProps } from "widgets/ContainerWidget/widget";
import { Elevations } from "widgets/wds/constants";
import { ContainerComponent } from "widgets/wds/Container";
import { UNSTABLE_executeDynamicTrigger } from "workers/Evaluation/asyncWorkerActions";
import {
  createThread,
  fetchMessages,
  fetchThreads,
  sendMessage,
  submitActionRequestOutput,
  type AiAssistantStreamLine,
} from "../api";
import { AIChat } from "../component";
import {
  assertAssistantActionRequestMessage,
  isAssistantActionRequestMessage,
  isAssistantTextMessage,
  isReadyForSubmission,
  type AssistantActionRequestMessage,
  type Message,
  type Thread,
} from "../component/types";
import type { ToolCall } from "../component/types/toolCall";
import { StreamLineParser } from "../lib/StreamLineParser";
import { StreamReader } from "../lib/StreamReader";
import {
  anvilConfig,
  autocompleteConfig,
  defaultsConfig,
  metaConfig,
  methodsConfig,
  propertyPaneContent,
  propertyPaneStyle,
} from "./config";
import * as MessageCitationMapper from "./mappers/messageCitationMapper";
import * as MessageMapper from "./mappers/messageMapper";

export interface WDSAIChatWidgetProps
  extends ContainerWidgetProps<WidgetProps> {
  threadId: string;
}

export interface WDSAIChatWidgetState extends WidgetState {
  isQueryLoading: boolean;
  messages: Message[];
}

class WDSAIChatWidget extends BaseWidget<
  WDSAIChatWidgetProps,
  WDSAIChatWidgetState
> {
  static type = "WDS_AI_CHAT_WIDGET";

  state: WDSAIChatWidgetState = {
    isQueryLoading: true,
    messages: [],
  };

  static getConfig(): WidgetBaseConfiguration {
    return metaConfig;
  }

  static getDefaults(): WidgetDefaultProps {
    return defaultsConfig;
  }

  static getPropertyPaneConfig() {
    return [];
  }
  static getPropertyPaneContentConfig() {
    return propertyPaneContent;
  }

  static getPropertyPaneStyleConfig() {
    return propertyPaneStyle;
  }

  static getMethods() {
    return methodsConfig;
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return autocompleteConfig;
  }

  static getSetterConfig(): SetterConfig | null {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
      },
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, unknown> {
    return {
      thread: undefined,
      messages: [],
      prompt: "",
      isWaitingForResponse: false,
      isThreadLoading: false,
    };
  }

  static getAnvilConfig(): AnvilConfig | null {
    return anvilConfig;
  }

  static getStylesheetConfig(): Stylesheet {
    return {};
  }

  componentDidMount() {
    // This handles cases where a component is mounted after switch from datasource or to view mode.
    // In these scenarios, the widget remains unselected, allowing us to request threads because the query is ready for use.
    if (this.props.threadId && !this.props.isWidgetSelected) {
      this.getMessages();
      this.setState({
        isQueryLoading: false,
      });
    }

    if (!this.props.threadId && !this.props.isWidgetSelected) {
      this.getThreads();
      this.setState({
        isQueryLoading: false,
      });
    }
  }

  componentDidUpdate(
    _: WDSAIChatWidgetProps,
    prevState: WDSAIChatWidgetState,
  ): void {
    //This handles the case where a widget has just been dropped.
    // Since a query is automatically created upon dropping the widget, we need to wait until the creation process is fully completed.
    if (
      !this.props.threadId &&
      !this.props.isThreadLoading &&
      this.state.messages.length === 0 &&
      !this.getQuery()?.isLoading
    ) {
      this.getThreads();
    }

    this.setState({
      isQueryLoading: this.getQuery()?.isLoading,
    });

    if (prevState.messages !== this.state.messages) {
      this.state.messages.forEach(async (message, messageIndex) => {
        if (!isAssistantActionRequestMessage(message)) return;

        if (isReadyForSubmission(message)) {
          // eslint-disable-next-line no-console
          console.log("[FC]: actionRequest to submit", message);
          this.props.updateWidgetMetaProperty("isWaitingForResponse", true);

          try {
            const response = await submitActionRequestOutput({
              widgetId: this.props.widgetId,
              queryId: this.getQuery().config.id,
              runId: message.runId,
              threadId: message.threadId,
              outputs: message.content,
              activeEnv: getCurrentEnvironmentId(store.getState()),
              viewMode: getIsViewMode(store.getState()),
            });

            this.updateWidgetFromStream(response);

            this.setState((state) =>
              create(state, (draft) => {
                const message = draft.messages[messageIndex];

                assertAssistantActionRequestMessage(message);

                message.outputSubmissionStatus = "success";
              }),
            );
          } catch (error) {
            this.props.updateWidgetMetaProperty("isWaitingForResponse", false);
            this.setState((state) =>
              create(state, (draft) => {
                const message = draft.messages[messageIndex];

                assertAssistantActionRequestMessage(message);

                message.outputSubmissionStatus = "error";
              }),
            );
          }

          return;
        }

        // Start executing tool calls
        message.content.forEach(async (toolCall, toolCallIndex) => {
          if (toolCall.executionStatus !== "pending") return;

          // eslint-disable-next-line no-console
          console.log("[FC]: toolCall to execute", toolCall);

          this.setState((state) =>
            create(state, (draft) => {
              const message = draft.messages[messageIndex];

              assertAssistantActionRequestMessage(message);

              message.content[toolCallIndex].executionStatus = "inProgress";
            }),
          );

          try {
            let trigger: string;

            switch (toolCall.entity.type) {
              case "Query":
                trigger = `{{${toolCall.entity.name}.run(${toolCall.entity.arguments})}}`;
                break;
              case "JSFunction": {
                const functionArguments = JSON.parse(toolCall.entity.arguments);
                const jsFunction = this.getJSFunctionById(toolCall.entity.id);
                const jsArguments =
                  jsFunction.actionConfiguration.jsArguments || [];

                if (
                  jsArguments.length !== objectKeys(functionArguments).length
                ) {
                  throw new Error("No all arguments are provided");
                }

                const isKeysMatch = objectKeys(functionArguments).every(
                  (key) =>
                    jsArguments.find((arg) => arg.name === key) !== undefined,
                );

                if (!isKeysMatch) {
                  throw new Error("Arguments keys do not match");
                }

                const argsArray = jsArguments.map(
                  ({ name }) => functionArguments[name],
                );

                trigger = `{{${toolCall.entity.name}.apply(null, ${JSON.stringify(argsArray)})}}`;
                break;
              }
              default:
                throw new Error();
            }

            // eslint-disable-next-line no-console
            console.log("[FC]: trigger to execute", trigger);

            const response = await UNSTABLE_executeDynamicTrigger(trigger);

            // eslint-disable-next-line no-console
            console.log("[FC]: execution response", response);

            if (response.errors.length > 0) {
              throw new Error(response.errors[0]?.errorMessage?.message);
            }

            this.setState((state) =>
              create(state, (draft) => {
                const message = draft.messages[messageIndex];

                assertAssistantActionRequestMessage(message);

                message.content[toolCallIndex] = {
                  ...message.content[toolCallIndex],
                  executionStatus: "success",
                  output: response.result,
                };
              }),
            );
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log("[FC]: toolCall execution error", error);

            this.setState((state) =>
              create(state, (draft) => {
                const message = draft.messages[messageIndex];

                assertAssistantActionRequestMessage(message);

                message.content[toolCallIndex] = {
                  ...message.content[toolCallIndex],
                  executionStatus: "error",
                  error:
                    error instanceof Error ? error.message : "Unknown error",
                };
              }),
            );
          }
        });
      });
    }
  }

  getThreads = async () => {
    this.props.updateWidgetMetaProperty("isThreadLoading", true);

    try {
      const threads = await fetchThreads({
        queryId: this.getQuery().config.id,
        widgetId: this.props.widgetId,
      });

      this.handleGetThreadsComplete(threads);
    } catch (error) {
      this.setState({
        messages: [
          this.getErrorAssistantMessage(
            isApiResponseError(error) ? error.message : "Unknown error",
          ),
        ],
      });

      this.props.updateWidgetMetaProperty("isThreadLoading", false);
    }
  };

  getInitialAssistantMessage = memoizeOne(
    (initialAssistantMessage, initialAssistantSuggestions): Message[] => {
      return [
        {
          id: Math.random().toString(),
          role: "assistant",
          type: "text",
          content: initialAssistantMessage || "",
          promptSuggestions: initialAssistantSuggestions || [],
          citations: [],
          createdAt: new Date(),
        },
      ];
    },
  );

  getErrorAssistantMessage = memoizeOne((message: string): Message => {
    return {
      id: Math.random().toString(),
      role: "assistant",
      type: "error",
      content: message || "",
      citations: [],
      createdAt: new Date(),
    };
  });

  handleGetThreadsComplete = (threads: Thread[]) => {
    if (threads.length === 0) {
      this.setState({
        messages: this.getInitialAssistantMessage(
          this.props.initialAssistantMessage,
          this.props.initialAssistantSuggestions,
        ),
      });

      this.props.updateWidgetMetaProperty("isThreadLoading", false);

      return;
    }

    // BE send the latest updated thread at the top of the array
    this.props.updateWidgetMetaProperty("threadId", threads[0].id);
    this.getMessages();
  };

  getMessages = async () => {
    try {
      const messages = await fetchMessages({
        threadId: this.props.threadId,
        queryId: this.getQuery().config.id,
        widgetId: this.props.widgetId,
      });

      this.props.updateWidgetMetaProperty("isThreadLoading", false);
      this.setState({
        messages: [
          ...this.getInitialAssistantMessage(
            this.props.initialAssistantMessage,
            this.props.initialAssistantSuggestions,
          ),
          ...messages.reverse(),
        ],
      });
    } catch (error) {
      this.setState({
        messages: [
          this.getErrorAssistantMessage(
            isApiResponseError(error) ? error.message : "Unknown error",
          ),
        ],
      });
    } finally {
      this.props.updateWidgetMetaProperty("isThreadLoading", false);
    }
  };

  appendMessage = (message: Message) => {
    this.setState((state) => ({
      messages: [...state.messages, message],
    }));
  };

  handleMessageSubmit = async () => {
    const prompt = this.props.prompt;

    this.appendMessage({
      createdAt: new Date(),
      id: Math.random().toString(),
      content: prompt,
      role: "user",
      type: "text",
    });
    this.props.updateWidgetMetaProperty("isWaitingForResponse", true);
    this.props.updateWidgetMetaProperty("prompt", "");

    const state = store.getState();
    const activeEnv = getCurrentEnvironmentId(state);
    const queryId = this.getQuery().config.id;
    const viewMode = getIsViewMode(state);

    try {
      const response = await sendMessage({
        threadId: this.props.threadId,
        widgetId: this.props.widgetId,
        message: prompt,
        activeEnv,
        queryId,
        viewMode,
      });

      await this.updateWidgetFromStream(response);
    } catch (error) {
      this.appendMessage(
        this.getErrorAssistantMessage(
          error instanceof Error ? error.message : "Unknown error",
        ),
      );
    } finally {
      this.props.updateWidgetMetaProperty("isWaitingForResponse", false);
    }
  };

  handleToolCallApprove = (messageId: string, toolCallId: string) => {
    this.setState((state) =>
      create(state, (draft) => {
        const message = draft.messages.find(
          (message) => message.id === messageId,
        );

        if (!message || !isAssistantActionRequestMessage(message)) return;

        const toolCall = message.content.find(
          (toolCall) => toolCall.id === toolCallId,
        );

        if (!toolCall || !toolCall.isApprovalRequired) return;

        toolCall.executionStatus = "pending";
      }),
    );
  };

  updateWidgetFromStream = async (response: Response) => {
    const reader = response.body?.getReader();

    if (!reader) return;

    const streamReader = new StreamReader(reader);
    const streamLineParser = new StreamLineParser<AiAssistantStreamLine>();

    for await (const lines of streamReader.read()) {
      const parsedLine = streamLineParser.parse(lines);

      if (!parsedLine) continue;

      switch (parsedLine.event) {
        case "thread.run.created":
          this.props.updateWidgetMetaProperty(
            "threadId",
            parsedLine.data.threadId,
          );
          break;
        case "thread.message.created":
          this.props.updateWidgetMetaProperty("isWaitingForResponse", false);
          this.appendMessage(
            MessageMapper.fromDto({
              ...parsedLine.data,
              role: "assistant",
              type: "text",
              message: [
                {
                  text: {
                    value: "",
                  },
                  type: "text",
                },
              ],
            }),
          );
          break;
        case "thread.run.tool_call_request": {
          const toolCalls: ToolCall[] = parsedLine.data.actions.map(
            (toolCall) => {
              return {
                id: toolCall.toolCallId,
                entity: {
                  id: toolCall.function.entityId,
                  type: toolCall.function.entityType,
                  name: toolCall.function.name,
                  iconLocation: toolCall.function.iconLocation,
                  arguments: JSON.stringify(
                    JSON.parse(toolCall.function.arguments),
                  ),
                },
                output: toolCall.function.output,
                executionStatus: toolCall.isApprovalRequired
                  ? "pendingApproval"
                  : "pending",
                isApprovalRequired: toolCall.isApprovalRequired,
              };
            },
          );

          const newMessage: AssistantActionRequestMessage = {
            id: Math.random().toString(),
            role: "assistant",
            type: "action_request",
            content: toolCalls,
            runId: parsedLine.data.runId,
            threadId: parsedLine.data.threadId,
            outputSubmissionStatus: "pending",
            createdAt: Date.now(),
          };

          this.appendMessage(newMessage);

          break;
        }
        case "thread.message.delta": {
          const targetMessageIndex = this.state.messages.findIndex(
            (message) => message.id === parsedLine.data.messageId,
          );

          if (targetMessageIndex === -1) return;

          this.setState((prevState) =>
            create(prevState, (draft) => {
              const message = draft.messages[targetMessageIndex];

              message.content =
                message.content + parsedLine.data.delta.content[0].text.value;
            }),
          );

          break;
        }
        case "citations.content": {
          const targetMessageIndex = this.state.messages.findIndex(
            (message) => message.id === parsedLine.data.messageId,
          );

          if (targetMessageIndex === -1) return;

          this.setState((prevState) =>
            create(prevState, (draft) => {
              const message = draft.messages[targetMessageIndex];

              if (!isAssistantTextMessage(message)) return;

              message.citations =
                parsedLine.data.citations
                  .map(MessageCitationMapper.fromDto)
                  .filter((citation) => citation !== null) || [];
            }),
          );

          break;
        }
        case "citations.ref": {
          const targetMessageIndex = this.state.messages.findIndex(
            (message) => message.id === parsedLine.data.messageId,
          );

          if (targetMessageIndex === -1) return;

          this.setState((prevState) =>
            create(prevState, (draft) => {
              const message = draft.messages[targetMessageIndex];

              if (isAssistantActionRequestMessage(message)) return;

              message.content = message.content + parsedLine.data.refs;
            }),
          );

          break;
        }
        case "error": {
          this.props.updateWidgetMetaProperty("isWaitingForResponse", false);

          if (
            typeof parsedLine.data === "object" &&
            "message" in parsedLine.data
          ) {
            this.appendMessage(
              this.getErrorAssistantMessage(parsedLine.data.message),
            );
          } else {
            this.appendMessage(this.getErrorAssistantMessage("Unknown error"));
          }

          break;
        }
      }
    }
  };

  handlePromptChange = (prompt: string) => {
    this.props.updateWidgetMetaProperty("prompt", prompt);
  };

  handleApplyAssistantSuggestion = (suggestion: string) => {
    this.props.updateWidgetMetaProperty("prompt", suggestion);
  };

  onDeleteThread = async () => {
    if (!this.props.threadId) {
      this.setState({
        messages: this.getInitialAssistantMessage(
          this.props.initialAssistantMessage,
          this.props.initialAssistantSuggestions,
        ),
      });

      return;
    }

    this.props.updateWidgetMetaProperty("isThreadLoading", true);

    try {
      // Instead of deleting the thread, we create a new one so that we can continue using the previous one
      const threadId = await createThread({
        widgetId: this.props.widgetId,
        queryId: this.getQuery().config.id,
      });

      this.props.updateWidgetMetaProperty("threadId", threadId);
      this.setState({
        messages: this.getInitialAssistantMessage(
          this.props.initialAssistantMessage,
          this.props.initialAssistantSuggestions,
        ),
      });
    } catch (error) {
      this.appendMessage(
        this.getErrorAssistantMessage(
          isApiResponseError(error) ? error.message : "Unknown error",
        ),
      );
    } finally {
      this.props.updateWidgetMetaProperty("isThreadLoading", false);
    }
  };

  getJSFunctionById = (jsFunctionId: string): JSAction => {
    const state = store.getState();
    const jsCollection = getJSCollections(state).find((jsCollection) =>
      jsCollection.config.actions.find(
        (jsFunction) => jsFunction.id === jsFunctionId,
      ),
    );

    if (!jsCollection) {
      throw new Error("JSFunction not found");
    }

    const jsFunction = jsCollection.config.actions.find(
      (jsFunction) => jsFunction.id === jsFunctionId,
    );

    if (!jsFunction) {
      throw new Error("JSFunction not found");
    }

    return jsFunction;
  };

  getQuery(): ActionData {
    const state = store.getState();
    const queryName = this.props.queryRun;

    return state.entities.actions.find(
      (action: ActionData) => action.config.name === queryName,
    );
  }

  getWidgetView(): ReactNode {
    return (
      <ContainerComponent
        elevatedBackground
        elevation={Elevations.CARD_ELEVATION}
        noPadding
        widgetId={this.props.widgetId}
      >
        <AIChat
          chatDescription={this.props.chatDescription}
          chatTitle={this.props.assistantName}
          isQueryLoading={this.state.isQueryLoading}
          isThreadLoading={this.props.isThreadLoading}
          isWaitingForResponse={this.props.isWaitingForResponse}
          onApplyAssistantSuggestion={this.handleApplyAssistantSuggestion}
          onDeleteThread={this.onDeleteThread}
          onPromptChange={this.handlePromptChange}
          onSubmit={this.handleMessageSubmit}
          onToolCallApprove={this.handleToolCallApprove}
          prompt={this.props.prompt}
          promptInputPlaceholder={this.props.promptInputPlaceholder}
          queryId={this.getQuery()?.config.id}
          size={this.props.chatHeightSize}
          thread={this.state.messages}
          threadId={this.props.threadId}
        />
      </ContainerComponent>
    );
  }
}

export default WDSAIChatWidget;
