import { Text } from "@appsmith/wds";
import type { VisualizationElements } from "entities/Action";
import { Result } from "PluginActionEditor/components/PluginActionResponse/components/Visualization/components/Result";
import React from "react";
import ReactJsonView from "react-json-view";
import { type ToolCall } from "../types/toolCall";
import styles from "./styles.module.css";
interface ToolCallResponseViewerProps {
  toolCall: ToolCall;
  visualizationEnabled?: boolean;
  visualizationElements: VisualizationElements | null;
}

export const ToolCallResponseViewer = ({
  toolCall,
  visualizationElements,
  visualizationEnabled = false,
}: ToolCallResponseViewerProps) => {
  if (
    toolCall.executionStatus !== "success" &&
    toolCall.executionStatus !== "error"
  ) {
    return null;
  }

  if (toolCall.executionStatus === "error") {
    return (
      <div className={styles.toolCallResponseViewer}>
        <Text className={styles.error}>{toolCall.error}</Text>
      </div>
    );
  }

  if (visualizationEnabled && visualizationElements) {
    return (
      <div className={styles.toolCallResponseViewer}>
        <Result data={toolCall.output} elements={visualizationElements} />
      </div>
    );
  }

  return (
    <div className={styles.toolCallResponseViewer}>
      <ReactJsonView
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        src={toolCall.output}
      />
    </div>
  );
};
