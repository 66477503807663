import { Flex, Icon, Markdown, Text } from "@appsmith/wds";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import React, { useEffect } from "react";

import { ChatMessageToolCallItem } from "./ChatMessageToolCallItem/ChatMessageToolCallItem";
import { ChatPromptSuggestions } from "./ChatPromptSuggestions";
import { ChatDocumentCitationItem } from "./citationItems/ChatDocumentCitationItem";
import { ChatNotionPageCitationItem } from "./citationItems/ChatNotionPageCitationItem";
import { ChatWebPageCitationItem } from "./citationItems/ChatWebPageCitationItem";
import styles from "./styles.module.css";
import {
  isAssistantActionRequestMessage,
  isAssistantTextMessage,
  isUserMessage,
  type AssistantActionRequestMessage,
  type AssistantTextMessage,
  type Message,
  type MessageCitation,
  type UserMessage,
  isAssistantErrorMessage,
} from "./types";
import type { ToolCall } from "./types/toolCall";

export interface ChatMessageItemProps {
  message: Message;
  isAssistantSuggestionVisible?: boolean;
  onApplyAssistantSuggestion?: (suggestion: string) => void;
  selectedCitationId?: string | null;
  onCitationOpen: (citationId: string) => void;
  isCitationLoading?: boolean;
  onToolCallApprove: (messageId: string, toolCallId: string) => void;
  onToolCallSelect: (toolCall: ToolCall) => void;
}

// Sometimes the openAI sends citation with ids from a previous message.
// So we are storing the citations in a map so that if the citations in the current message is not found,
// It must be from a previous message and we can try to look it up in the map.
let citationsMap: Record<string, MessageCitation> = {};

export const ChatMessageItem = (props: ChatMessageItemProps) => {
  const {
    isAssistantSuggestionVisible,
    message,
    onApplyAssistantSuggestion,
    onCitationOpen,
    onToolCallApprove,
    onToolCallSelect,
    selectedCitationId,
  } = props;

  const getFormattedDate = (createdAt: number | Date) => {
    if (createdAt instanceof Date) return format(createdAt, "HH:mm, dd MMM");

    // Convert to milliseconds for JavaScript Date
    const date = new Date(createdAt * 1000);

    // Get the user's local time zone
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert the date to the user's time zone
    const zonedDate = toZonedTime(date, timeZone);

    // Format the date in "HH:mm, dd MMM" format
    return format(zonedDate, "HH:mm, dd MMM");
  };

  const handleCitationOpen = (citation: MessageCitation) => {
    onCitationOpen(citation.id);
  };

  useEffect(function cleanupCitationsMap() {
    return () => {
      citationsMap = {};
    };
  }, []);

  const assistantTextMessage = (message: AssistantTextMessage) => (
    <Flex direction="row" flex={1} gap="spacing-4" isInner minWidth="spacing-0">
      <Flex
        direction="column"
        gap="spacing-3"
        isInner
        minWidth="spacing-0"
        width="100%"
      >
        {message.createdAt && (
          <Text size="caption">{getFormattedDate(message.createdAt)}</Text>
        )}
        {message.content.trim() && (
          <Markdown
            data-markdown=""
            data-testid="t--ai-chat-message-item-content"
            extraComponents={{
              span: ({ node, ...props }) => {
                if (!node?.properties["dataCitationId"]) {
                  return <span {...props}>{props.children}</span>;
                }

                const citationId = String(node.properties["dataCitationId"]);

                // In most cases, the citation is present in the current message,
                // so we can just find it in the current message.
                const citation =
                  message.citations.find(({ id }) => id === citationId) ||
                  // but sometimes the citation is from a previous message,
                  // so we can try to find it in the map.
                  citationsMap[citationId];

                // we store the citation in the map so that we can use it in the next message if needed.
                if (citation) {
                  citationsMap[citationId] = citation;
                }

                if (!citation) {
                  return null;
                }

                if (citation.integrationType === "WEB_SCRAPE") {
                  return (
                    <ChatWebPageCitationItem
                      citation={citation}
                      onOpen={handleCitationOpen}
                      selectedCitationId={selectedCitationId}
                    />
                  );
                }

                if (citation.integrationType === "NOTION") {
                  return <ChatNotionPageCitationItem citation={citation} />;
                }

                return (
                  <ChatDocumentCitationItem
                    citation={citation}
                    onOpen={handleCitationOpen}
                    selectedCitationId={selectedCitationId}
                  />
                );
              },
            }}
          >
            {message.content}
          </Markdown>
        )}
        {isAssistantSuggestionVisible && message.promptSuggestions && (
          <ChatPromptSuggestions
            onApplyAssistantSuggestion={onApplyAssistantSuggestion}
            suggestions={message.promptSuggestions}
          />
        )}
      </Flex>
    </Flex>
  );

  const assistantErrorMessage = (message: AssistantTextMessage) => (
    <Flex
      direction="column"
      gap="spacing-4"
      isInner
      minWidth="spacing-0"
      width="100%"
    >
      {message.createdAt && (
        <Text color="negative" size="caption">
          {getFormattedDate(message.createdAt)}
        </Text>
      )}
      {message.content.trim() && (
        <Text color="negative" data-testid="t--ai-chat-message-item-content">
          {message.content}
        </Text>
      )}
    </Flex>
  );

  const userMessage = (message: UserMessage) => (
    <Flex
      alignItems="end"
      className={styles.userMessage}
      direction="column"
      gap="spacing-3"
      isInner
    >
      {message.createdAt && (
        <Text size="caption">{getFormattedDate(message.createdAt)}</Text>
      )}
      <Text
        data-testid="t--ai-chat-message-item-content"
        wordBreak="break-word"
      >
        {message.content}
      </Text>
    </Flex>
  );

  const assistantActionRequestMessage = (
    message: AssistantActionRequestMessage,
  ) => (
    <Flex direction="row" flex={1} gap="spacing-3" isInner minWidth="spacing-0">
      <Flex
        direction="column"
        gap="spacing-4"
        minWidth="spacing-0"
        width="100%"
      >
        <div className={styles.toolCalls}>
          {message.content.map((toolCall) => (
            <ChatMessageToolCallItem
              key={toolCall.id}
              messageId={message.id}
              onApprove={onToolCallApprove}
              onSelect={onToolCallSelect}
              toolCall={toolCall}
            />
          ))}

          {message.outputSubmissionStatus === "error" && (
            <Flex direction="row" gap="spacing-3" isInner minWidth="spacing-0">
              <Icon color="negative" name="exclamation-circle" />
              Error sending outputs
            </Flex>
          )}
        </div>
      </Flex>
    </Flex>
  );

  return (
    <Flex
      className={styles.messageListItem}
      data-role={isUserMessage(message) ? "user" : "assistant"}
      data-testid="t--ai-chat-message-item"
      direction={isUserMessage(message) ? "row-reverse" : "row"}
      key={message.id}
    >
      {isUserMessage(message) && userMessage(message)}
      {isAssistantTextMessage(message) && assistantTextMessage(message)}
      {isAssistantErrorMessage(message) && assistantErrorMessage(message)}
      {isAssistantActionRequestMessage(message) &&
        assistantActionRequestMessage(message)}
    </Flex>
  );
};
