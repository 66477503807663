import type { ICONS } from "@appsmith/wds";
import { Button, Icon, Text, useSidebar } from "@appsmith/wds";
import clsx from "clsx";
import React, { memo, useState, type ReactNode } from "react";
import type { ToolCall } from "../types/toolCall";
import styles from "./styles.module.css";

interface ChatMessageToolCallItemProps {
  messageId: string;
  toolCall: ToolCall;
  onApprove: (messageId: string, toolCallId: string) => void;
  onSelect: (toolCall: ToolCall) => void;
}

export const ChatMessageToolCallItem = memo(
  ({
    messageId,
    onApprove,
    onSelect,
    toolCall,
  }: ChatMessageToolCallItemProps) => {
    const [isArgumentsOpen, setIsArgumentsOpen] = useState(false);
    const { setState } = useSidebar();
    const { entity, executionStatus, id } = toolCall;

    const resolveStatusDescription = (): string | null => {
      switch (executionStatus) {
        case "pendingApproval":
          return `Function ${entity.name} requires approval`;
        case "inProgress":
          return `Executing function ${entity.name}`;
        case "success":
          return `Successfully executed function ${entity.name}`;
        case "error":
          return `Failed to execute function ${entity.name}`;
        default:
          return entity.name;
      }
    };

    const resolveActionButtons = (): ReactNode | null => {
      switch (executionStatus) {
        case "pendingApproval":
          return (
            <Button
              color="neutral"
              onPress={() => onApprove(messageId, id)}
              variant="outlined"
            >
              Approve
            </Button>
          );
        case "success":
          return (
            <Button
              color="neutral"
              onPress={() => {
                onSelect(toolCall);
                setState("expanded");
              }}
              variant="outlined"
            >
              View response
            </Button>
          );
        default:
          return null;
      }
    };

    const resolveIconName = (): keyof typeof ICONS | null => {
      switch (executionStatus) {
        case "pendingApproval":
          return "exclamation-circle";
        case "inProgress":
          return "sparkles";
        case "success":
          return "circle-check";
        case "error":
          return "cancel";
        default:
          return null;
      }
    };

    const shouldShowArguments =
      entity.arguments &&
      entity.arguments.length > 0 &&
      executionStatus === "pendingApproval";
    const icon = resolveIconName();

    return (
      <div className={styles.chatMessageToolCallItem}>
        {icon && (
          <Icon
            className={clsx(styles.icon, {
              [styles.iconInProgress]: executionStatus === "inProgress",
              [styles.iconPendingApproval]:
                executionStatus === "pendingApproval",
              [styles.iconSuccess]: executionStatus === "success",
              [styles.iconError]: executionStatus === "error",
            })}
            name={icon}
          />
        )}

        <Text color="neutral-subtle">{resolveStatusDescription()}</Text>

        {shouldShowArguments && (
          <div className={styles.arguments}>
            <Button
              color="neutral"
              icon={isArgumentsOpen ? "chevron-down" : "chevron-right"}
              onPress={() => setIsArgumentsOpen((prev) => !prev)}
              variant="ghost"
            >
              Parameters
            </Button>
            {isArgumentsOpen && (
              <pre className={styles.argumentsJson}>
                {JSON.stringify(JSON.parse(entity.arguments), null, 2)}
              </pre>
            )}
          </div>
        )}

        {resolveActionButtons()}
      </div>
    );
  },
);
