export class StreamLineParser<T> {
  parse(line: string): T | null {
    const [firstLine, secondLine] = line.split("\n");

    // in case of incorrect request data, BE returns only one line
    if (firstLine && !secondLine) {
      const data = firstLine.replace("data:", "");

      try {
        const errorMessage = JSON.parse(data);

        if (!errorMessage) return null;

        return {
          event: "error",
          // handling the case when there is no error object in the responseMeta, for example, with a invalid URL
          data: errorMessage.responseMeta.error || errorMessage.responseMeta,
        } as T;
      } catch (e) {
        return null;
      }
    }

    if (!firstLine || !secondLine) return null;

    const event = firstLine.replace("event:", "");
    const data = secondLine.replace("data:", "");

    if (!event || !data) return null;

    try {
      const parsedData = JSON.parse(data);

      return {
        event,
        data: parsedData,
      } as T;
    } catch (e) {
      return null;
    }
  }
}
