import {
  Text,
  Flex,
  Modal,
  Button,
  Markdown,
  ModalBody,
  IconButton,
  ModalHeader,
  ModalContent,
  type SidebarState,
} from "@appsmith/wds";
import React, { useState } from "react";

import styles from "./styles.module.css";

interface ChatHeaderProps {
  title?: string;
  description?: string;
  onDeleteThread?: () => void;
  isDeleteThreadDisabled?: boolean;
  sidebarState?: SidebarState;
  setSidebarState?: (state: SidebarState) => void;
}

export const ChatHeader = (props: ChatHeaderProps) => {
  const {
    description,
    isDeleteThreadDisabled,
    onDeleteThread,
    setSidebarState,
    sidebarState,
    title,
  } = props;
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);

  return (
    <>
      <Flex alignItems="center" className={styles.header} gap="spacing-3">
        <Text fontWeight={600} lineClamp={1} size="subtitle">
          {title}
        </Text>
        {description && (
          <Button
            icon="info-square-rounded"
            onPress={() => setIsDescriptionModalOpen(true)}
            variant="ghost"
          />
        )}
        {onDeleteThread && (
          <IconButton
            className={styles.deleteButton}
            color="neutral"
            data-testid="t--ai-chat-header-delete-button"
            icon="trash"
            isDisabled={isDeleteThreadDisabled}
            onPress={onDeleteThread}
            variant="ghost"
          />
        )}
        <Button
          color="neutral"
          icon={
            sidebarState === "collapsed"
              ? "layout-sidebar-right-expand"
              : "layout-sidebar-right-collapse"
          }
          onPress={() => {
            if (setSidebarState) {
              setSidebarState(
                sidebarState === "collapsed" ? "expanded" : "collapsed",
              );
            }
          }}
          variant="ghost"
        />
      </Flex>

      <Modal
        isOpen={isDescriptionModalOpen}
        setOpen={setIsDescriptionModalOpen}
        size="small"
      >
        <ModalContent>
          <ModalHeader title="Information about the bot" />
          <ModalBody>
            {description && <Markdown>{description}</Markdown>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
